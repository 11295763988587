import React from "react";
import theme from "theme";
import { Theme, Text, Image, Button, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Welcome to The Coach
			</title>
			<meta name={"description"} content={"Unlock a better you with The Coach, your personal guide to improving health, boosting performance, and elevating your quality of life"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Box
			min-width="100px"
			min-height="100vh"
			background="#141D19"
			display="flex"
			flex-direction="column"
			align-items="center"
			padding="0px 7% 7% 7%"
			flex="1"
		>
			<Text
				margin="0px 0px 0px 0px"
				font="normal 400 50px/80px 'Druk', Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif"
				text-align="center"
				color="#f3f4f4"
				padding="15% 0px 3% 0px"
			>
				Welcome to
			</Text>
			<Image
				src="https://uploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06:39:58.186Z"
				display="block"
				width="80%"
				max-width="450px"
				srcSet="https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65093a108bd7e600185e78db/images/Logo_img.png?v=2023-09-19T06%3A39%3A58.186Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text margin="0px 0px 0px 0px" font="600 22px/1.55 --fontFamily-sans" color="#f3f4f4" padding="6% 0px 3% 0px">
				Unlock a better you with The Coach, your personal guide to improving 
health, boosting performance, and elevating your quality of life
				<br />
				<br />
				Whether you're a beginner looking to start your wellness journey or 
seeking advanced lifestyle changes, we've got you covered.
			</Text>
			<Button
				background="#ff6d03"
				color="#f3f4f4"
				margin="auto 0px 0px 0px"
				font="600 24px/1.55 --fontFamily-sans"
				href="/2"
				type="link"
				sm-text-decoration-line="initial"
				text-decoration-line="initial"
				padding="12px 42px 12px 42px"
			>
				Continue
			</Button>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65093a108bd7e600185e78d9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script place={"endOfHead"} rawKey={"6511ac8515cc038ca22e9a60"}>
				{"window.mainTracker = \"gtag\";\nwindow.gtagTrackerID = \"G-2WL1E4LC2C\";\nfunction gtag() {\n  dataLayer.push(arguments);\n}\n\nfunction loadGTM() {\n  (function (w, d, s, i) {\n    var f = d.getElementsByTagName(s)[0],\n      j = d.createElement(s);\n    j.async = true;\n    j.src = \"https://www.googletagmanager.com/gtag/js?id=\" + i;\n    j.onload = function() {\n      gtag(\"js\", new Date());\n      gtag(\"config\", i, {});\n    }\n    f.parentNode.insertBefore(j, f);\n  })(window, document, \"script\", window.gtagTrackerID);\n}\n\nwindow.onload = function() {\n  if (window.requestIdleCallback) {\n    window.requestIdleCallback(loadGTM, { timeout: 150000 });\n  } else {\n    setTimeout(loadGTM, 2000);\n  }\n}\n"}
			</script>
		</RawHtml>
	</Theme>;
});